import styled from "styled-components";

const GooglePlayContainer = styled.div`
    max-width: 150px;
`;

export interface GooglePlayLinkProps {
  applicationId: string;
}

export const GooglePlayLink = (props: GooglePlayLinkProps): JSX.Element => {
  return <GooglePlayContainer>
    <a href={`https://play.google.com/store/apps/details?id=${props.applicationId}&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1`}>
      <img alt='Get it on Google Play' style={{ width: "100%" }} src='https://play.google.com/intl/en_us/badges/static/images/badges/fi_badge_web_generic.png' />
    </a>
  </GooglePlayContainer>;
};