import { DefaultTheme } from "styled-components";

export const lightTheme: DefaultTheme = {
  color: {
    background: "#FFFFFF",
    boxColor: "#FFFFFF",
    text: "#111111",
    languageToggleBackground: "#FFFFFF",
    languageToggleSecondary: "#008EFA",
    languageToggleText: "#111111",
    languageToggleHoverSelection: "#dedede",
    socialIconDefaultColor: "black",
    socialIconDefaultBackgroundColor: "#dfdfdf"
  }
};

export const darkTheme: DefaultTheme = {
  color: {
    background: "#171728",
    boxColor: "#202038",
    text: "#E5E5E5",
    languageToggleBackground: "#202038",
    languageToggleSecondary: "#008EFA",
    languageToggleText: "#E5E5E5",
    languageToggleHoverSelection: "#2f2f4f",
    socialIconDefaultColor: "white",
    socialIconDefaultBackgroundColor: "#202038"
  }
};