import { useState } from "react";
import { useTranslation } from "react-i18next";
import styled, { ThemeProvider } from "styled-components";
import { ApplicationShowcase, ApplicationShowcaseProps } from "./ApplicationShowcase";
import { ShadowContainer } from "./Components";
import { overriddenDarkModeKey, pageWidth, topContainerBreakpoint } from "./Constants";
import { Footer } from "./Footer";
import { SplitContainer } from "./SplitContainer";
import { lightTheme, darkTheme } from "./theme";
import AquaseedIcon from "./AquaseedIcon.svg";
import { GooglePlayLink } from "./GooglePlayLink";

const AppContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 30px 30px 30px;
  background-color: ${props => props.theme.color.background};
  min-height: 100vh;
  color: ${props => props.theme.color.text};
`;

const TopContainer = styled.div`
    margin-bottom: 20px;
    @media (max-width: ${topContainerBreakpoint}px) {
        margin-bottom: 70px;
    }
`;

const SiteDialog = styled.div`
    padding: 22px;
`;

const HomepageContainer = styled.div`
    max-width: ${pageWidth}px;
`;

const getAquaseedData = (t: (text: string) => string): ApplicationShowcaseProps => {
  return {
    title: "Aquaseed",
    description: t("aquaseedDescription"),
    downloadLinkComponents: [
      <GooglePlayLink key={1} applicationId={"com.formicer.aquaseed"} />
    ],
    icon: AquaseedIcon
  };
};

const App = (): JSX.Element => {
  const { t } = useTranslation();

  
  const storageUsesDarkTheme = window.localStorage.getItem(overriddenDarkModeKey);
  let newTheme: boolean;
  if (storageUsesDarkTheme !== null) {
    newTheme = String(storageUsesDarkTheme).toLowerCase() === "true";
  }
  else {
    newTheme = window.matchMedia("(prefers-color-scheme: dark)").matches;
  }
  
  // false: light, true: dark
  const [usesDarkTheme, setDarkTheme] = useState(newTheme);

  const setColorTheme = (newTheme: boolean) => {
    window.localStorage.setItem(overriddenDarkModeKey, String(newTheme));
    setDarkTheme(newTheme);
  };
  return (
    <ThemeProvider theme={usesDarkTheme ? darkTheme : lightTheme}>
      <AppContainer>
        <HomepageContainer>
          <TopContainer>
            <SplitContainer weights={[1, 2]} directionFlipPoint={topContainerBreakpoint}>
              <SiteDialog>
                <h1>Formicer</h1>
                <p>{t("smallDialogDescription")}</p>
              </SiteDialog>
              <ShadowContainer>
                <p>{t("formicerDescription")}</p>
              </ShadowContainer>
            </SplitContainer>
          </TopContainer>
          <ShadowContainer>
            <ApplicationShowcase {...getAquaseedData(t)} />
          </ShadowContainer>
          <Footer setTheme={setColorTheme} currentTheme={usesDarkTheme} />
        </HomepageContainer>
      </AppContainer>
    </ThemeProvider>
  );
};

export default App;
