import i18n, { Resource } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources: Resource = {
  en: {
    translation: {
      languageFullName: "English",
      smallDialogDescription: "Applications from Finland, made with love ❤️",
      formicerDescription: "Who are we? We are a group of teenagers from Finland, who are interested in entrepreneurship and programming. Formicer started as a tiny idea in 2021, but now we have published our first application Aquaseed!",
      aquaseedDescription: "Aquaseed is our first application. It reminds you to drink water regularly throughout the day, so you can live a healthier life.",
      googleDisclaimer: "Google Play and the Google Play logo are trademarks of Google LLC.",
      email: "Email"
    }
  },
  fi: {
    translation: {
      languageFullName: "Suomi",
      smallDialogDescription: "Sovelluksia Suomesta, tehty rakkaudella ❤️",
      formicerDescription: "Keitä olemme? Olemme ryhmä nuoria Suomesta, jotka ovat kiinnostuineita yrittäjyydestä ja ohjelmoinnista. Formicer alkoi pienenä ideana vuonna 2021, mutta nyt olemme jo julkaisseet ensimmäisen sovelluksemme Aquaseedin!",
      aquaseedDescription: "Aquaseed on ensimmäinen sovelluksemme. Se muistuttaa sinua juomaan vettä säännöllisesti pitkin päivää, jotta voit elää terveellisempää elämää.",
      googleDisclaimer: "Google Play ja Google Play ‑logo ovat Google LLC:n tavaramerkkejä.",
      email: "Sähköposti"
    }
  }
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    fallbackLng: "en",
    nonExplicitSupportedLngs: true,
    supportedLngs: Object.keys(resources),
    appendNamespaceToCIMode: false
  });

export default i18n;