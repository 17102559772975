import styled from "styled-components";
import { smallScreenBreakpoint } from "./Constants";
import { SplitContainer } from "./SplitContainer";

const LeftContainer = styled.div`
    padding-right: 20px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    @media (max-width: ${smallScreenBreakpoint}px) {
        padding-right: 0px;
    }
`;

const RightContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TopPart = styled.div``;

const BottomPart = styled.div`
    display: flex;
`;

const IconContainer = styled.div`
    max-width: 200px;
`;

const ApplicationTitle = styled.h2`
    @media (max-width: ${smallScreenBreakpoint}px) {
        text-align: center;
    }
`;

export interface ApplicationShowcaseProps {
    title: string;
    description: string;
    downloadLinkComponents: JSX.Element[];
    icon: string;
}

export const ApplicationShowcase = (props: ApplicationShowcaseProps): JSX.Element => {
  return <SplitContainer directionFlipPoint={smallScreenBreakpoint}>
    <LeftContainer>
      <TopPart>
        <ApplicationTitle>{props.title}</ApplicationTitle>
        <p>{props.description}</p>
      </TopPart>
      <BottomPart>
        {props.downloadLinkComponents}
      </BottomPart>
    </LeftContainer>
    <RightContainer>
      <IconContainer>
        <img style={{ width: "100%" }} src={props.icon} alt="" />
      </IconContainer>
    </RightContainer>
  </SplitContainer>;
};