import { FunctionComponent } from "react";
import styled from "styled-components";

interface SocialIconProps {
  icon: JSX.Element,
  href?: string,
  backgroundColor?: string,
  color?: string,
  title?: string
}

interface ContainerProps {
  backgroundColor?: string,
  color?: string
}

const size = 35;
const Container = styled.div<ContainerProps>`
  height: ${size}px;
  width: ${size}px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${size / 2}px;
  background-color: ${props => props.backgroundColor || props.theme.color.socialIconDefaultBackgroundColor};
  color: ${props => props.color || props.theme.color.socialIconDefaultColor};
`;

export const SocialIcon: FunctionComponent<SocialIconProps> = (props) => {
  const innerComponent = <Container backgroundColor={props.backgroundColor} color={props.color} title={props.title}>
    {props.icon}
  </Container>;

  if (props.href) return <a href={props.href}>{innerComponent}</a>;
  else return innerComponent;
};