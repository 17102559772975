import { FunctionComponent } from "react";
import styled from "styled-components";

export interface SplitContainerProps {
  children: JSX.Element[];
  weights?: number[];
  direction?: "row" | "column";
  directionFlipPoint?: number;
}

interface FlexContainerProps {
  direction?: "row" | "column";
  directionFlipPoint?: number;
}

const FlexContainer = styled.div`
  display: flex;
  flex-direction: ${(props: FlexContainerProps) => props.direction || "row"};
  width: 100%;
  align-items: center;
  justify-content: center;
  ${(props: FlexContainerProps) => {
    const defaultDirection = props.direction || "row";
    // Flip between row <-> column
    const newDirection = defaultDirection === "column" ? "row" : "column";
    return `@media (max-width: ${(props.directionFlipPoint)}px) {
              flex-direction: ${newDirection};
          }`;
  }}
`;


interface FlexChildProps {
  weight: number;
}

const FlexChild = styled.div`
  flex: ${(p: FlexChildProps) => p.weight + ""};
`;

export const SplitContainer: FunctionComponent<SplitContainerProps> = (props): JSX.Element => {
  const weights = props.weights || [];
  return <FlexContainer direction={props.direction} directionFlipPoint={props.directionFlipPoint}>
    {
      props.children.map((child, index) => {
        return <FlexChild key={index} weight={index < weights.length ? weights[index] : 1}>
          {child}
        </FlexChild>;
      })
    }
  </FlexContainer>;
};