import React, { FunctionComponent } from "react";
import styled from "styled-components";

interface SpaceBetweenContainerProps {
  direction?: "row" | "column";
  directionFlipPoint?: number;
}

interface FlexContainerProps {
  direction: "row" | "column";
  directionFlipPoint: number;
}

const Container = styled.div`
  flex-direction: ${(props: FlexContainerProps) => props.direction};
  display: flex;
  gap: 10px;
  justify-content: space-between;
  ${(props: FlexContainerProps) => {
    // Flip between row <-> column
    const newDirection = props.direction === "column" ? "row" : "column";
    return `@media (max-width: ${(props.directionFlipPoint)}px) {
              flex-direction: ${newDirection};
              align-items: center;
          }`;
  }}
`;

export const SpaceBetweenContainer: FunctionComponent<SpaceBetweenContainerProps> = ({
  direction = "row",
  directionFlipPoint = 850,
  ...props
}) => {
  return (
    <Container direction={direction} directionFlipPoint={directionFlipPoint}>
      {props.children}
    </Container>
  );
};