import { useTranslation } from "react-i18next";
import { defaultTheme, SingleValue } from "react-select";
import Select from "react-select";
import Toggle from "react-toggle";
import styled, { useTheme } from "styled-components";
import { footerBreakpoint, footerLeftPartBreakpoint } from "./Constants";
import "./ReactToggle.css";
import { SpaceBetweenContainer } from "./SpaceBetweenContainer";
import { FaGooglePlay, FaInstagram, FaLinkedin, FaTiktok, FaTwitter } from "react-icons/fa";
import { FiMail } from "react-icons/fi";
import { SocialIcon } from "./SocialIcon";

const Container = styled.div`
  margin-top: 40px;
  padding: 20px;
`;

const RightPart = styled.div`
  text-align: right;
  max-width: 330px;
  @media (max-width: ${footerBreakpoint}px) {
      text-align: center;
  }
`;

const ThemeToggleIcon = styled.span`
  position: absolute;
  top: 5px;
  left: -5px;
`;

const LeftPart = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  @media (max-width: ${footerBreakpoint}px) {
    justify-content: center;
    margin-bottom: 20px;
  }
  @media (max-width: ${footerLeftPartBreakpoint}px) {
    flex-direction: column;
  }
`;

const Copyright = styled.p`
  white-space: nowrap;
  margin: 0px;
`;

const LanguageSelectorContainer = styled.div`
  min-width: 120px;
`;

const DarkThemeToggleContainer = styled.div`
  display: flex;
`;

const SocialLinkContainer = styled.div`
  display: flex;
  gap: 5px;
`;

const TrademarkDisclaimer = styled.p`
  margin: 0px;
  font-size: 0.9rem;
`;

interface FooterProps {
  setTheme: (newTheme: boolean) => void;
  currentTheme: boolean;
}

export const Footer = (props: FooterProps): JSX.Element => {
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  // What is cimode???
  const langs = (i18n.options.supportedLngs || i18n.languages || ["en"]).filter(lang => lang !== "cimode");

  const languageOptions = langs.map(c => {
    const code = c.split("-")[0];
    return {
      value: code,
      label: i18n.t("languageFullName", { lng: code })
    };
  }).filter((code, index, self) =>
    index === self.findIndex((t) => t.value === code.value)
  );

  const currentLanguage = i18n.language.split("-")[0];

  const onChangeLanguage = (newValue: SingleValue<{ value: string; label: string; }>) => {
    i18n.changeLanguage(newValue?.value || i18n.options.fallbackLng?.toString() || "en");
  };

  return <Container>
    <SpaceBetweenContainer directionFlipPoint={footerBreakpoint}>
      <LeftPart>
        <Copyright>
          &copy; Formicer {new Date().getFullYear()}
        </Copyright>
        <LanguageSelectorContainer>
          <Select
            options={languageOptions}
            value={{ value: currentLanguage, label: i18n.t("languageFullName", { lng: currentLanguage }) }}
            onChange={onChangeLanguage}
            isSearchable={false}
            theme={{
              colors: {
                ...defaultTheme.colors,
                primary: theme.color.languageToggleSecondary,
                neutral0: theme.color.languageToggleBackground,
                neutral80: theme.color.languageToggleText,
                primary25: theme.color.languageToggleHoverSelection
              },
              borderRadius: defaultTheme.borderRadius,
              spacing: { ...defaultTheme.spacing }
            }}
            menuPlacement={"top"}
          />
        </LanguageSelectorContainer>
        <DarkThemeToggleContainer>
          <Toggle
            onChange={() => props.setTheme(!props.currentTheme)}
            defaultChecked={props.currentTheme}
            icons={{
              checked: <ThemeToggleIcon>🌑</ThemeToggleIcon>,
              unchecked: <ThemeToggleIcon>☀️</ThemeToggleIcon>
            }}
          />
        </DarkThemeToggleContainer>
        <SocialLinkContainer>
          <SocialIcon icon={<FiMail size={18} title={t("email")} />} href="mailto:contact@formicer.com" title={t("email")} />
          <SocialIcon icon={<FaTwitter size={18} title="Twitter" />} href="https://twitter.com/formicer" title="Twitter" />
          <SocialIcon icon={<FaInstagram size={18} title="Instagram" />} href="https://www.instagram.com/formicerapps/" title="Instagram" />
          <SocialIcon icon={<FaGooglePlay size={18} title="Google Play" />} href="https://play.google.com/store/apps/developer?id=Formicer" title="Google Play" />
          <SocialIcon icon={<FaTiktok size={18} title="TikTok" />} href="https://www.tiktok.com/@formicer" title="TikTok" />
          <SocialIcon icon={<FaLinkedin size={18} title="LinkedIn" />} href="https://fi.linkedin.com/in/formicer-apps-224923227" title="LinkedIn" />
        </SocialLinkContainer>
      </LeftPart>
      <RightPart>
        <TrademarkDisclaimer>{t("googleDisclaimer")}</TrademarkDisclaimer>
      </RightPart>
    </SpaceBetweenContainer>
  </Container>;
};